<template>

  <div id="root">

    <OrchardMap :company="company"
                :kpin="kpin"
                :companies="companies"
                :geolocation="geolocation"
                :selected.sync="selected"
                :hovered.sync="hovered"
                :auto-center="auto_center"
                :auto-center-mode="auto_center_mode"
                :active_block="selected_block"
                :active_row="selected_row"
                :bay-color="bayColor"
                ref="map">

    </OrchardMap>

    <div class="prompt print-prompt" v-if="false">
      <form class="form" @submit.prevent="createPdf">
        <label for="format">Page size</label>
        <select id="format" v-model="form.size">
          <option value="a0">A0 (slow)</option>
          <option value="a1">A1</option>
          <option value="a2">A2</option>
          <option value="a3">A3</option>
          <option value="a4" selected>A4</option>
          <option value="a5">A5 (fast)</option>
        </select>
        <label for="resolution">Resolution</label>
        <select id="resolution" v-model="form.resolution">
          <option value="72">72 dpi (fast)</option>
          <option value="150">150 dpi</option>
          <option value="300">300 dpi (slow)</option>
        </select>
        <button type="submit" :disabled="!export_enabled">Export PDF</button>
      </form>
    </div>

    <GradientSlider :class="gslider_class"
                    v-model="color_stops"
                    :colors="colors"
                    :min="color_min"
                    :max="color_max"/>

    <div :class="panel_group_class">

      <transition-group name="panel-slide" id="panel-transition">

        <div id="panel-sel" v-if="selected_details !== null" key="panel-sel">
          <div class="flex-col">

            <div class="info-grid">

              <div class="info-item">
                <div>
                  <div class="title">
                    Bay {{ selected_details.bay.name }} <badge type="info" v-if="selected_details.bay.calibrated">CAL</badge>
                  </div>
                  <div class="average" :style="count_style(selected_details.bay)">
                    density
                    <span>{{ selected_details.bay.average }}/m²</span>
                  </div>
                </div>
                <div class="stats">
                  <span class="count">
                    <span :class="selected_details.bay.type"/>{{ kFormat(selected_details.bay.count) }}
                  </span>
                  <span class="area">
                    <span class="icon-area"/> {{ formatAreas(selected_details.bay) }}
                  </span>
                </div>
              </div>

              <span class="vert-divider"></span>

              <div class="info-item">
                <div>
                  <div class="title">
                    Row {{ selected_details.row.name }} <badge type="info" v-if="selected_details.row.calibrated">CAL</badge>
                  </div>
                  <div class="average" :style="count_style(selected_details.row)">
                    density
                    <span>{{ selected_details.row.average }}/m&sup2;</span>
                    <span v-if="selected_details.row.stdev != null">&sigma;={{selected_details.row.stdev}}/m&sup2;</span>
                  </div>
                </div>
                <div class="stats">
                  <span class="count">
                    <span :class="selected_details.row.type"/>{{ kFormat(selected_details.row.count) }}
                  </span>
                  <span class="area">
                    <span class="icon-area"/> {{ formatAreas(selected_details.row) }}
                  </span>
                </div>
              </div>

              <span class="vert-divider"></span>

              <div class="info-item block">
                <div>
                  <div class="title">
                    Block {{ selected_details.block.name }} <badge type="info" v-if="selected_details.block.calibrated">CAL</badge>
                  </div>
                  <div class="average" :style="count_style(selected_details.block)">
                    density
                    <span>{{ selected_details.block.average }}/m&sup2;</span>
                    <span v-if="selected_details.block.stdev != null">&sigma;={{selected_details.block.stdev}}/m&sup2;</span>
                  </div>
                </div>
                <div class="stats">
                  <span class="count">
                    <span :class="selected_details.block.type"/>{{ kFormat(selected_details.block.count) }}
                  </span>
                  <div class="area">
                    <span class="icon-area"/> {{ formatAreas(selected_details.block) }}
                  </div>
                </div>
              </div>

            </div>
            <a :href="bay_image" target="_blank" class="bay-img-link">
              <img width="100%" alt="Bay Photo" height="auto" @load="onImgLoad(this)" @error="onImgError"
                   :src="bay_image_thumb"/>
            </a>

          </div>

        </div>

        <div id="panel-subgroup" key="hover" v-if="hovered_details && hovered_details.row">

          <div id="panel-hov">
            <div class="info-grid">

              <div class="info-item" v-if="hovered_details.bay">
                <div class="title">
                  Bay {{ hovered_details.bay.name }}
                  <badge type="info" v-if="hovered_details.bay.calibrated">CAL</badge>
                </div>
                <div class="average" :style="count_style(hovered_details.bay)" v-if="hovered_details.bay.average">
                  density
                  <!-- TODO icon missing -->
                  <span>{{ hovered_details.bay.average }}/m&sup2;</span>
                </div>
                <div class="stats">
                  <div class="count">
                    <span :class="hovered_details.bay.type"/>{{ kFormat(hovered_details.bay.count) }}
                  </div>
                  <div class="area">
                    <span class="icon-area"/> {{ formatAreas(hovered_details.bay) }}
                  </div>
                  <!-- TODO remove location? -->
                </div>
              </div>

              <span class="vert-divider" v-if="hovered_details.bay"></span>

              <div class="info-item" v-if="hovered_details.row">
                <div class="title">
                  Row {{ hovered_details.row.name }}
                  <badge type="info" v-if="hovered_details.row.calibrated">CAL</badge>
                </div>
                <div class="average" :style="count_style(hovered_details.row)" v-if="hovered_details.row.average">
                  density
                  <!-- TODO add js style in this section. -->
                  <!-- TODO change average to density -->
                  <span> {{ hovered_details.row.average }}/m&sup2;</span>
                  <span v-if="hovered_details.row.stdev != null">&sigma;={{ hovered_details.row.stdev }}/m&sup2;</span>
                </div>
                <div class="stats">
                  <div class="count">
                    <span :class="hovered_details.row.type"/>{{ kFormat(hovered_details.row.count) }}
                  </div>
                  <div class="area">
                    <span class="icon-area"/> {{ formatAreas(hovered_details.row) }}
                  </div>
                </div>
              </div>
              <!--               <span class="vert-divider" v-if="hovered_details.row"></span>

                         <div class="info-item">
                             <div class="title">
                               Orchard {{ hovered_details.orchard.name }}
                               <badge type="info" v-if="hovered_details.orchard.calibrated">CAL</badge>
                             </div>
                             <div class="average" :style="count_style(hovered_details.orchard)"
                                  v-if="hovered_details.orchard.average">
                               density
                               &lt;!&ndash; TODO add js style in this section. &ndash;&gt;
                               &lt;!&ndash; TODO change average to density &ndash;&gt;
                               <span> {{ hovered_details.orchard.average }}/m&sup2;</span>
                               <span
                                 v-if="hovered_details.orchard.stdev != null">&sigma;={{ hovered_details.orchard.stdev }}/m&sup2;</span>
                             </div>
                             <div class="stats">
                               <div class="count">
                                 <span :class="hovered_details.orchard.type"/>{{ kFormat(hovered_details.orchard.count) }}
                               </div>
                               <div class="area">
                                 <span class="icon-area"/> {{ formatAreas(hovered_details.orchard) }}
                               </div>
                             </div>
                           </div>-->

            </div>
          </div>

        </div>
        <div id="panel-subgroup" key="hover" v-else-if="orchard_details">
          <div id="panel-hov">
            <div class="info-grid">

              <div class="info-item">
                <div class="title">
                  Orchard {{ orchard_details.name }} <badge type="info" v-if="orchard_details.calibrated">CAL</badge>
                </div>
                <div class="average" :style="count_style(orchard_details)" v-if="orchard_details.average">
                  density
                  <!-- TODO add js style in this section. -->
                  <!-- TODO change average to density -->
                  <span> {{ orchard_details.average }}/m&sup2;</span>
                  <span v-if="orchard_details.stdev != null">&sigma;={{orchard_details.stdev}}/m&sup2;</span>
                </div>
                <div class="stats">
                  <div class="count">
                    <span :class="orchard_details.type"/>{{ kFormat(orchard_details.count) }}
                  </div>
                  <div class="area">
                    <span class="icon-area"/> {{ formatAreas(orchard_details) }}
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </transition-group>

    </div>

  </div>
</template>

<!-- ------------------------------------------------------------------------------------------------------------------------ -->

<script>
import Vue from "vue";
import {SlideXLeftTransition} from 'vue2-transitions'
import {setPageRules} from '../../../../../public/static/customizePageStyle.js'
import {jsPDF} from "jspdf"

import {scanImageUrl} from "@/util/api";
import GradientSlider from "@/components/UIComponents/Acuris/GradientSlider.vue";
import OrchardMap from "@/components/UIComponents/Acuris/OrchardMap.vue";
import {colorGradient, rgb} from "@/util/color";
import {getArea, getCenter} from "ol/extent";
import {Badge} from "@/components/UIComponents";

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
  },
  unbind: function (el) {
  },
});

export default {
  created() {

  },
  components: {
    Badge,
    OrchardMap,
    GradientSlider,
    SlideXLeftTransition,
  },
  props: {},
  data() {
    return {
      type_list: ["orchard", "block", "row", "bay"],
      form: {
        size: 'a4',
        resolution: '300',
      },
      dims: {
        "a0": [1189, 841], "a1": [841, 594], "a2": [594, 420],
        "a3": [420, 297], "a4": [297, 210], "a5": [210, 148],
      },
      printDuplicates: [],
      selected: [],
      selected_details: null,
      hovered: [],
      hovered_details: null,
      orchard_details: null,
      bay_image: '',
      bay_image_thumb: '',
      color_min: 0,
      color_max: 100,
      color_stops: [25, 50, 75],
      colors: [
        [255, 0, 0, 1.0],
        [255, 213, 0, 1.0],
        [0, 255, 0, 1.0],
        [0, 0, 255, 1.0],
        [200, 0, 255, 1.0],
      ],
      default_color: [153, 153, 153],
      export_enabled: true,
      geolocation: null,
      gslider_class: ['gslider', 'gslider_normal'],
      panel_group_class: ['panel-group', 'panel-group_normal'],
      auto_center: false,
      auto_center_mode: "point",
      selected_row: null,
      selected_block: null,
    }
  },
  computed: {
    print_size() {
      let w = window.innerWidth;
      let h = window.innerHeight;
      if (w > (h * 1.414)) {
        w = h * 1.414;
      } else {
        h = w / 1.414;
      }
      // let w = window.innerHeight * 1.414;
      return `${w}px ${h}px`
    },
    clrs() {
      return [this.color_min].concat(this.color_stops).concat([this.color_max]).map((e, i) => [this.colors[i], e])
    },
    scan() {
      return this.$root.scan
    },
    orchard() {
      return this.$root.orchard
    },
    company() {
      return (this.$root.orchard || {}).company
    },
    companies() {
      return this.$root.orchards.map((o) => o.company).filter((v, i, a) => a.indexOf(v) === i)
    },
    kpin() {
      return (this.$root.orchard || {}).kpin
    }
  },
  watch: {
    orchard: function (_) {
      this.selected = []
    },
    scan: function (s) {
      this.export_enabled = !!s
      this.$refs.map.refreshColors()
      this.updateSelected(this.selected)
      console.info("Scan changed", s)
      this.orchard_details = this.orchardDetails(s)
      console.info("Orchard details", this.orchard_details)
    },
    selected_block: function(_) {
      this.$refs.map.refreshColors()
    },
    selected_row: function(_) {
      this.$refs.map.refreshColors()
    },
    color_stops: function (_) {
      this.$refs.map.refreshColors()
    },
    hovered: function (v) {
      if (this.$root.scan) {
        if (this.$root.scan.name) {
          let features = v.filter(f => !!f.get('acuris'))
          if (features.length === 0) {
            this.hovered_details = null;
          }
          for (let type of this.type_list.toReversed()) {
            for (let f of features) {
              if (f.get('acuris') === type) {
                this.hovered_details = this.featureDetails(f, features)
                return
              }
            }
          }
        }
        this.hovered_details = null
      }
      this.hovered_details = null
    },
    selected: function (v) {
      this.updateSelected(v)
    },
  },
  mounted() {
    if (!this.$route.query['embedded']) {
      this.gslider_class = ['gslider', 'gslider_normal']
      this.panel_group_class = ['panel-group', 'panel-group_normal']
    } else {
      this.gslider_class = ['gslider', 'gslider_embedded']
      this.panel_group_class = ['panel-group', 'panel-group_embedded']
    }

    window.addEventListener('beforeprint', async (_) => { // create print listeners
      await setPageRules.adds([
        {ruleName: 'size', ruleValue: this.print_size},
        {ruleName: 'margin', ruleValue: '0'},
      ]);
    });
    window.addEventListener('afterprint', (_) => setPageRules.clean());
    this.$root.$on('pdf-download', (_) => (
      this.createPdf()
    ))
    const self = this
    window.setGeolocation = function (p) {
      self.geolocation = p
    }
    window.setOrchardKPIN = function (kpin) {
      if (self.$root.orchard && self.$root.orchard.kpin === kpin) {
        console.debug(`Orchard ${kpin} already selected`)
        return true
      }
      for (let orchard of self.$root.orchards) {
        if (orchard.kpin === kpin) {
          self.$root.orchard = orchard
          return true
        }
      }
      return false
    }
    window.setOrchardBlock = function (block) {
      if (self.selected_block && self.$root.selected_block === block) {
        console.debug(`Block ${block} already selected`)
        return true
      }
      self.selected_block = block
      return false
    }
    window.setOrchardBlockRow = function (row) {
      row = "" + row
      if (self.selected_row && self.selected_row === row) {
        console.debug(`Row ${row} already selected`)
        return true
      }
      self.selected_row = row
      return false
    }
    window.setScanName = function (name) {
      if (self.$root.scan && self.$root.scan.name === name) {
        console.debug(`Scan ${name} already selected`)
        return true
      }
      for (let scan of self.$root.scans) {
        if (scan.name === name) {
          self.$root.scan = scan
          return true
        }
      }
      console.info(`Scan ${name} not found. Full refresh next time`)
      self.$root.full_refresh = true
      return false
    }
    window.setAutoCenter = function (val) {
      self.auto_center = val
    }
    window.setAutoCenterMode = function (val) {
      self.auto_center_mode = val
    }
  },
  methods: {
    count_style(item) {
      return {"background-color": rgb(item.color || this.default_color, 0.3)}
    },
    updateSelected(v) {
      if (this.$root.scan) {
        console.debug("scan:", this.$root.scan.name)
        console.debug("v:", v)

        if (v.length === 0) {
          this.selected_details = null
          return
        }
        let theSelected = v[0]
        console.debug("theSelected:", theSelected, theSelected.getGeometry(), getCenter(theSelected.getGeometry().getExtent()))
        let other = this.$refs.map.getFeaturesAtCoordinate(getCenter(theSelected.getGeometry().getExtent()), true)
        console.debug("Features at location", other)
        let selectedDetails = this.featureDetails(theSelected, other.filter(f => !!f.get('acuris')))

        console.debug("selectedDetails:", selectedDetails)
        if (selectedDetails.bay.count) {
          scanImageUrl(this.$root.orchard.company,
            selectedDetails.orchard.name,
            this.$root.scan.id, selectedDetails.block.name, selectedDetails.row.name, selectedDetails.bay.name, true).then(urls => {
            console.debug("select, new bay_url:", urls)
            this.bay_image = urls.full
            this.bay_image_thumb = urls.thumb
            this.selected_details = selectedDetails
            /*loadBayScanDetails(this.$root.orchard.company, selectedDetails.orchard.name, this.$root.scan.id, selectedDetails.block.name, selectedDetails.row.name, selectedDetails.bay.name).then(scan_details => {
              console.debug(scan_details)
            })*/ // TODO load details and draw on top of the bay image
          })
        } else {
          this.bay_image = ''
          this.bay_image_thumb = ''
          this.selected_details = selectedDetails
        }
      } else {
        this.selected_details = null
      }
    },
    subScans(scan) {
      return Object.values(scan).filter((x) => x.counts !== undefined)
    },
    orchardDetails(scan) {
      return {name: this.kpin, ...this.scanCounts(scan, this.subScans(scan.blocks))}
    },
    featureDetails(feature, other) {
      let orchardLocation = this.$refs.map.mapFeatureOrchardLocation(feature)
      let scan = this.$root.scan
      let by_type = Object.assign({}, ...other.filter(f => !!f.get('acuris')).map((x) => ({[x.get('acuris')]: x})))
      return {
        orchard: orchardLocation.kpin === undefined ? null : {name: orchardLocation.kpin, ...this.scanCounts(scan, this.subScans(scan.blocks), by_type.orchard)},
        block: orchardLocation.block === undefined ? null : {name: orchardLocation.block, ...this.scanCounts(scan.blocks[orchardLocation.block], this.subScans(scan.blocks[orchardLocation.block] || {}), by_type.block)},
        row: orchardLocation.row === undefined ? null : {name: orchardLocation.row, ...this.scanCounts((scan.blocks[orchardLocation.block] || {})[orchardLocation.row], this.subScans((scan.blocks[orchardLocation.block] || {})[orchardLocation.row] || {}), by_type.row)},
        bay: orchardLocation.bay === undefined ? null : {name: orchardLocation.bay, ...this.scanCounts(((scan.blocks[orchardLocation.block] || {})[orchardLocation.row] || {})[orchardLocation.bay], undefined, by_type.bay)},
      }
    },
    scanCounts(scan, subscans = undefined, feature = undefined) {
      if (scan) {
        let type = Object.keys(scan.counts)[0]
        if (type) {
          let count = scan.counts[type]
          let area = scan.info.area
          let average = count / area
          let stdev = null;
          let calibrated = scan.info.calibrated;
          if (subscans !== undefined) {
            let devsq = subscans.map((subscan) => ((subscan.counts[type] || 0) / subscan.info.area - average) ** 2)
            let variance = devsq.reduce((a, b) => a + b) / subscans.length
            stdev = Math.sqrt(variance).toFixed(1)
          }
          return {
            count: count,
            type: type,
            area: area,
            full_area: feature ? getArea(feature.getGeometry()) : undefined,
            average: average.toFixed(1),
            stdev: stdev,
            color: this.countColor(average),
            calibrated: calibrated
          }
        }
      }
      return {
        full_area: feature ? getArea(feature.getGeometry()) : undefined,
      }
    },
    createPdf() {
      console.debug("submit triggered:", this.form)

      let format = this.form.size;
      let resolution = this.form.resolution;
      let dim = this.dims[format];
      const mapCanvas = document.createElement('canvas');
      mapCanvas.setAttribute("crossOrigin", "anonymous");
      mapCanvas.width = Math.round((dim[0] * resolution) / 25.4);
      mapCanvas.height = Math.round((dim[1] * resolution) / 25.4);

      this.export_enabled = false;
      document.body.style.cursor = 'progress';

      console.debug("mapCanvas:", mapCanvas)
      console.debug("this.$refs.map:", this.$refs.map)

      this.$refs.map.drawOnCanvas(mapCanvas).then((c) => {
        console.debug("Got drawn canvas")
        const pdf = new jsPDF({orientation: "landscape"});
        pdf.addImage(c.toDataURL('image/jpeg'), 'JPEG', 0, 0, dim[0], dim[1]);
        pdf.save(`${this.kpin}-${this.orchard.name}-${this.scan.name}-${this.scan.updated_at || new Date().toLocaleString()}.pdf`);
        console.debug("Creating PDF Complete.")
      }).catch((e) => {
        console.error("PDF Creation failed", e)
      }).then(() => {
        this.export_enabled = true;
        document.body.style.cursor = 'auto';
      })
    },
    onImgError(a) { // replace
      console.error("onImgError", a)
      // TODO better check
      if (this.bay_image_thumb.indexOf('.thumb') >= 0) {
        this.bay_image_thumb = this.bay_image
      } else {
        this.bay_image_thumb = '/img/icons/bayimagenotfound.jpg'
      }
    },
    onImgLoad(e) {
      console.debug("onImgLoad: e:", e)
    },
    formatAreas(obj) {
      if (obj.area && obj.full_area) {
        if (Math.max(obj.area, obj.full_area) / Math.min(obj.area, obj.full_area) < 1.1) {
          return this.formatArea(obj.area)
        } else {
          return `${this.formatArea(obj.area)} of ${this.formatArea(obj.full_area)}`
        }
      } else if (obj.area) {
        return this.formatArea(obj.area)
      } else {
        return this.formatArea(obj.full_area)
      }
    },
    formatArea(area) { // takes number and returns string for html span, with meters squared or ha
      if (!area || isNaN(area)) {
        return '-'
      } else if (area < 10000) {
        return `${area.toFixed(1)} m²`;
      } else {
        return `${(area / 10000).toFixed(1)} ha`;
      }
    },
    kFormat(i) {
      if (i === undefined) {
        return 'N/A'
      }
      let k = false
      if (i >= 10000) {
        i = (i / 1000).toFixed(2)
        k = true
      }
      i = i.toLocaleString()
      if (k) {
        return `${i}k`
      }
      return `${i}`
    },
    bayColor(orchardLocation) {
      const color = this.scanCounts(((((this.$root.scan || {}).blocks || {})[orchardLocation.block] || {})[orchardLocation.row] || {})[orchardLocation.bay]).color
      return color ? rgb(color, 0.5) : undefined
    },
    countColor(count) {
      let clrs = this.clrs
      for (let i in clrs) {
        if (count < clrs[i][1]) {
          if (i === "0") {
            return clrs[0][0]
          } else {
            let min = clrs[i - 1]
            let max = clrs[i]
            return colorGradient((count - min[1]) / (max[1] - min[1]), min[0], max[0])
          }
        }
      }
      return clrs[clrs.length - 1][0]
    }
  }
}

</script>

<!-- ----------------------------------------------------------STYLE---------------------------------------------------------- -->

<style lang="scss">

.amplify-button {
  /* Your custom styles here */
  background-color: blue !important;
  color: white !important;
}

[class^="icon-"],
[class*=" icon-"] {
  text-align: center;
  min-width: 16px;
  margin: 0 7px;
}

</style>

<style lang="scss" scoped>

// body {
//     padding-bottom: env(safe-area-inset-bottom);
// }

.p-badge {
  padding: 2px 4px !important;
  position: relative;
  top: -2px;
}

span.vert-divider {
  display: inline-block;
  border-left: 3px solid #ccc;
  margin: 0 5px;
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

.prompt {
  position: absolute;
  text-align: center;

  background-color: lightgray;
  border-radius: 0.5em;
  padding: 0.5rem 1rem 1rem 1rem;

  h4 {
    margin-top: 16px;
  }

  .print-prompt {
    bottom: 3rem;
    left: 1rem;
  }
}

#panel-hov {
  order: 2;

  z-index: 3;

  height: fit-content;
  margin: 0;
  background: rgba(60, 60, 60, 0.7);
  color: white;
  border: 0;

  border-radius: 0 16px 16px 0;
  padding: 18px 16px 8px 16px;
}

#panel-transition {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.panel-group {
  position: absolute;
  left: 0;
  pointer-events: none;
}

#panel-sel {
  order: 1;

  pointer-events: auto;
  display: flex;
  z-index: 4;

  width: 24.4vw;
  min-width: 350px;
  //height: -webkit-fill-available;

  vertical-align: baseline;
  padding: 20px;
  //border: 2vh;

  background: #f2f2f2;
  color: black;
  filter: drop-shadow(0 0 6px black);

  overflow: hidden;
}

#panel-subgroup {
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: start;
  // flex-direction: column-reverse;
}

.flex-col {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  justify-content: flex-start;
}

.info-grid {
  order: 1;

  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: space-between;


  .info-item {
    display: flex;
    flex-direction: column;

    .average {
      padding: 5px 10px;
      border-radius: 10px;
      font-style: italic;
      display: flex;
      flex-direction: column;

      span {
        font-style: normal;
        font-size: large;
      }
    }

    .title {
      white-space: nowrap;
    }

    .stats {
      display: flex;
      flex-grow: 2;
      flex-direction: column;
      justify-content: space-evenly;
      padding-top: 5px;
    }
  }
}

#panel-sel {
  .bay-img-link {
    order: 2;

    display: flex;
    flex-grow: 1;

    flex-direction: column;
    justify-content: end;
    height: auto;
    max-height: fit-content;
  }

  .bay-img {
    order: 2;
    width: 100%;
    max-width: unset;
  }

  .panel_expand {
    position: absolute;
    right: -20px;
    top: 50%;

    background: #f2f2f2;
    width: 20px;
    height: 20px;

    padding: 15px 0 30px 0;
    border-radius: 0 5px 5px 0;
    text-align: center;
  }
}

// transitions --------------------------------------------------

.panel-slide {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.panel-slide-enter-active {
  transition: all .3s ease;
}

.panel-slide-leave-active {
  transition: all .3s ease;
}

.panel-slide-enter,
.panel-slide-leave-to {
  transform: translateX(-24.4vw);
}

.panel-slide-leave-to {
  opacity: 0;
}
// end transitions --------------------------------------------------

.info-item .stats span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: fade;
  height: 1.5em;
}

.stats span[class^="icon-"] {
  margin: 0 0;
}

.gslider_normal {
  position: absolute;
  top: 5em;
  right: 0.5em;
}

.gslider_embedded {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.panel-group_normal {
  top: 61px;
  height: -webkit-fill-available;
}

.panel-group_embedded {
  top: 0;
  bottom: 0;
}

.el-input__icon:after {
  display: none;
}

.el-input__icon::after {
  display: none;
}

.el-input__icon::before {
  display: none;
}

.el-select__caret el-input__icon el-icon-arrow-up::before {
  display: none;
}

.el-icon-arrow-up:before {
  display: none !important;
}

// .gradient-key.key-item {
//   font-size: 1em !important;
// }

@media (max-aspect-ratio: 8/5) and (min-aspect-ratio: 1/1) {
  #panel-sel {
    width: 33vw;
    padding: 8px;

    .info-grid {
      flex-direction: column;
      padding-bottom: 0.5rem;
    }

    .info-item {
      flex-direction: row;
    }

    .stats {
      text-align: right;
      padding-right: 16px;
    }

    .bay-img-link {
      flex-grow: unset;
    }
  }

  .vert-divider {
    border-bottom: 3px solid #ccc;
    margin: 5px 0;
  }
}

// tablet V aspect ratio
@media (max-aspect-ratio: 1/1) {
  .panel-group_normal {
    top: unset;
    height: auto;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .panel-group_embedded {
    top: unset;
    height: auto;
    bottom: 0;
  }
  .vue-slider-dot-tooltip-inner {
    font-size: 1.3rem;
  }

  #panel-transition {
    flex-direction: column;
    width: 100%;
    overflow: visible;
  }

  #panel-sel {
    order: 2;

    width: -webkit-fill-available;
    height: 40vh;

    .flex-col {
      flex-direction: row;
      width: -webkit-fill-available;
    }

    .info-grid {
      flex-direction: column;
    }

    .info-item {
      flex-direction: row;
    }

    .stats {
      text-align: right;
      padding-right: 16px;
    }

    .bay-img-link {
      flex-grow: unset;
    }

    .bay-img {
      width: auto;
    }
  }

  .vert-divider {
    border-bottom: 3px solid #ccc;
    margin: 5px 0;
  }

  #panel-subgroup {
    order: 1;
  }

  .bay-img-link img {
    height: 100%;
  }

  #panel-hov {
    display: none;
  }

  .panel-slide {
    flex-direction: column;
    width: 100%;
  }

  .panel-slide-enter,
  .panel-slide-leave-to {
    transform: translateY(40vh);
  }

  .gslider_normal {
    top: 4em;
  }
}

@media print {
  body {
    zoom: 10%; /* Zoom level for printing */
    margin: 0; /* Remove margin */
    text-align: center; /* Center the content */
  }

  .panel-group {
    display: none;
  }

  #panel-hov {
    display: none;
  }

  html, body {
    text-align: center;
  }

  .gslider {
    display: none;
  }
}

</style>
